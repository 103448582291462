import { type FunctionComponent } from "react";
import { StyledStatusUpdates } from "../status-updates/styled";
import { useParams } from "react-router";
import { FormBreadcrumb } from "../../../../components/bread-crumb/ReturnToStatusUpdatesBreadCrumb";
import { STATUS_PATH } from "../../../../components/app";
import { InternationalTransportPage } from "./components/feeds/InternationalTransport/Pages/InternationalTransportPage";
import { ForeignRoadsideAssistancePage } from "./components/feeds/foreignRoadsideAssistence/Pages/ForeignRoadsideAssistancePage";
import { NotFound } from "./components/feeds/NotFound";

export const Status: FunctionComponent = () => {
  const { statusType } = useParams();

  if (!statusType) {
    return <NotFound />;
  }

  const renderPage = () => {
    switch (statusType) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      case STATUS_PATH.INTERNATIONAL_TRANSPORT:
        return <InternationalTransportPage />;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      case STATUS_PATH.FOREIGN_ROADSIDE_ASSISTANCE:
        return <ForeignRoadsideAssistancePage />;
      default:
        return <NotFound />;
    }
  };

  return (
    <StyledStatusUpdates>
      <FormBreadcrumb />
      {renderPage()}
    </StyledStatusUpdates>
  );
};
